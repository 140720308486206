'use client'
import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'
import { useFollowUserMutation, useUnfollowUserMutation } from 'codegen/generated/flow'
import { useIsLoggedInQuery } from 'analytics/hooks/use-is-logged-in-query'

import { UserAvatar } from '@ask-cf/components/avatar'
import { PostUser } from '../feed/models'
import { StyledButton } from '@ask-cf/components/styled-button'
import { NewReleasesIcon } from '@ask-cf/assets/icons'
import { Topic } from '@ask-cf/constants/topics'
import { TopicBadge } from '@ask-cf/components/topic-badge'
import { useFormatTimeSpan } from '@ask-cf/hooks/use-format-time-span'
import { useFollow } from '@ask-cf/contexts/follow-user-context'
import { UserNameLabel } from '../user-name-label'
import { ASK_GENIE_USER_ID } from '@ask-cf/config'
import { Link } from '../link'

export function PostHeader({
  user,
  topic,
  postedAt,
  isBestAnswerHeader,
}: {
  user: PostUser
  topic?: Topic
  postedAt?: string
  isBestAnswerHeader?: boolean
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const formatTimeSpan = useFormatTimeSpan()
  const timeElapsed = React.useMemo(
    () => postedAt && formatTimeSpan(new Date().getTime() - new Date(postedAt).getTime()),
    [postedAt, formatTimeSpan],
  )
  const { data: isLoggedIn } = useIsLoggedInQuery()

  const CrafterProfileLinkWrapper = ({ children }: React.PropsWithChildren) =>
    user.profileUrls?.crafter ? (
      <Link target="_blank" href={user.profileUrls.crafter}>
        {children}
      </Link>
    ) : (
      <>{children}</>
    )

  return (
    <div className="xl:flex xl:justify-between">
      <div className="flex items-center gap-2">
        <CrafterProfileLinkWrapper>
          <UserAvatar avatarUrl={user.avatarUrl} size="mediumSecondary" />
        </CrafterProfileLinkWrapper>
        <div className="grow xl:grow-0">
          <div className="flex items-center">
            <CrafterProfileLinkWrapper>
              <UserNameLabel className="text-text-primary text-sm font-semibold leading-5">
                {(user.firstName && user.lastName) || user.id === ASK_GENIE_USER_ID
                  ? `${user.firstName} ${user.lastName}`
                  : t('user_label')}
              </UserNameLabel>
            </CrafterProfileLinkWrapper>
            {timeElapsed ? (
              <>
                <span className="mx-0.5 text-xs font-medium leading-4 text-gray-600">&#x2022;</span>
                <span
                  className="text-xs font-medium leading-4 text-gray-600"
                  suppressHydrationWarning
                >
                  {timeElapsed}
                </span>
              </>
            ) : null}
          </div>
          <div className="text-xs leading-4 text-gray-600">{user.subtitle}</div>
        </div>
        {isLoggedIn && user.id !== ASK_GENIE_USER_ID && user.following !== undefined ? (
          <FollowButton hasSubtitle={!!user.subtitle} userId={user.id} following={user.following} />
        ) : null}
      </div>
      {topic ? (
        <div className="inline-block">
          <TopicBadge topic={topic} className="mt-2 xl:mt-0" />
        </div>
      ) : null}
      {isBestAnswerHeader ? (
        <div className="inline-block">
          <div className="mt-2 flex items-center gap-1 rounded-[12px] bg-[#FAE6E9] px-2 py-1 xl:mt-0">
            <NewReleasesIcon />
            <span className="text-xs font-medium uppercase leading-4">{t('best_answer')}</span>
          </div>
        </div>
      ) : null}
    </div>
  )
}

function FollowButton({
  userId,
  hasSubtitle,
  following: initialFollowing,
}: {
  userId: string
  hasSubtitle: boolean
  following: boolean
}) {
  const { followedUsers, followUser, unfollowUser } = useFollow()

  const t = useTranslations('ask_cf.components.feed.post')
  const { mutate: followUserMutation, isLoading: isFollowUserLoading } = useFollowUserMutation()
  const { mutate: unfollowUserMutation, isLoading: isUnfollowUserLoading } =
    useUnfollowUserMutation()
  const [isHovered, setIsHovered] = React.useState(false)
  const isFollowing = followedUsers.includes(userId)

  React.useEffect(() => {
    if (initialFollowing && !isFollowing) {
      followUser(userId)
    }
  }, [initialFollowing, userId, followUser, isFollowing])

  function handleToggleFollow() {
    if (isFollowing) {
      unfollowUserMutation(
        { userID: userId },
        {
          onSuccess: data => {
            if (data.userUnfollow) {
              unfollowUser(userId)
            }
          },
        },
      )
    } else {
      followUserMutation(
        { userID: userId },
        {
          onSuccess: data => {
            if (data.userFollow) {
              followUser(userId)
            }
          },
        },
      )
    }
  }

  if (initialFollowing && isFollowing && !isHovered) {
    return null // Do not render the button if the user is already followed
  }

  return (
    <StyledButton
      noAnimation
      variant="transparent"
      size="minimal"
      className={tw(
        'text-xs font-medium leading-4 xl:pt-[1px]',
        hasSubtitle ? 'xl:self-start' : '',
      )}
      onClick={handleToggleFollow}
      disabled={isFollowUserLoading || isUnfollowUserLoading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isFollowing
        ? isHovered
          ? t('unfollow_button_text')
          : t('followed_question_label')
        : t('follow_button_text')}{' '}
    </StyledButton>
  )
}
